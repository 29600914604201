@import "~react-image-gallery/styles/css/image-gallery.css";
* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    outline: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: gordita_regular;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    background: 0 0;
}
input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
ol,
ul {
    list-style: none;
}
blockquote {
    quotes: none;
}
blockquote:after,
blockquote:before {
    content: "";
    content: none;
}
del {
    text-decoration: line-through;
}
address,
article,
aside,
footer,
header,
nav,
section {
    display: block;
}
body {
    font-family: "gordita_regular";
    font-size: 17px;
    overflow-x: hidden;
    min-width: 320px;
    background: #161619;
    /* -moz-transform: scale(0.8, 0.8);
    zoom: 0.8;
    zoom: 80%; */
}

body::-webkit-scrollbar {
    display: none;
}
input,
select,
textarea {
    font-family: "gordita_regular";
}
html {
    overflow: scroll !important;
    scroll-behavior: smooth !important;
}
html.modal-enabled {
    overflow: hidden !important;
}
a {
    color: #444;
}
.submit:hover,
a:hover {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}
.thumb {
    display: block;
}
.thumb img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.6em;
    font-size: 16px;
    color: #868686;
    font-family: gordita_regular;
}
.hidden {
    display: none;
}
br.clear {
    clear: both;
    line-height: 0;
}
.bold {
    font-family: gordita_medium;
}
.e-t-h {
    margin-bottom: 25px;
}
.e-t-h2 {
    font-size: 37px;
    color: #444;
    font-family: gordita_medium;
    letter-spacing: 0.02rem;
}
.wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: 1325px;
}
.b-regular {
    font-family: gordita_regular;
}
.b-medium {
    font-family: gordita_regular;
}
.b-semi {
    font-family: gordita_regular;
}
.white {
    color: #fff;
}
.black {
    color: #000;
}
a,
h1,
h2,
input {
    font-family: gordita_regular;
    color: #000;
    font-weight: 300;
}
input::-webkit-input-placeholder {
    color: #9b9a9a;
}
input:-moz-placeholder {
    color: #9b9a9a;
}

#main {
    padding: 87px 16px 11px 235px;
}
#main.shrink {
    padding: 87px 16px 11px 70px;
}

@media all and (max-width: 1550px) {
    #main {
        padding: 87px 16px 11px 70px;
    }
}
@media all and (max-width: 768px) {
    #main {
        padding: 87px 16px 11px 15px;
    }
}
@font-face {
    font-family: "gordita_regular";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/GorditaRegular.woff) format("woff");
}

@font-face {
    font-family: "gordita_medium";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/GorditaMedium.woff) format("woff");
}

@font-face {
    font-family: "gordita_bold";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/GorditaBold.woff) format("woff");
}

/* PAGINATION */

.pagination {
    display: flex;
    justify-content: center;
    cursor: pointer;

    flex-wrap: wrap;
}
.pagination a {
    /* padding: 10px 14px 7px 14px; */
    border-radius: 5px;
    color: #fff;
    background-color: #292929;
    border: 1px solid #333;
    font-size: 13px;
    margin: 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
}
.pagination .previous a,
.pagination .next a {
    width: 75px;
}
@media all and (max-width: 480px) {
    .pagination a {
        padding: 4px 12px;
    }
}
.pagination li.break a {
    display: flex;
    align-items: center;
    justify-content: center;
}
.previous .pagination__link {
    background-color: unset;
    color: #98999b;
}
.next .pagination__link {
    background-color: unset;
    color: #98999b;
}
.pagination__link {
    font-weight: bold;
}
.pagination__link--active a {
    color: #fff;
    background: #ff5b1e;
}
.pagination__link--disabled a {
    cursor: not-allowed;
}
.active_link_classname {
    color: #fff;
    background: #ff5b1e;
}

/* PAGINATION ENDS*/
/* date_picker */
.react-datepicker__month-container {
    float: left !important;
}
.react-datepicker__header {
    background: black !important;
    color: #fff !important;
    border-bottom: 1px solid #1b1c20 !important;
    padding: 15px 13px 13px 13px !important;
}
.react-datepicker__day--keyboard-selected {
    background: black !important;
}
.react-datepicker__navigation--next {
    top: 16px !important;
    right: 16px !important;
    border-left-color: #ccc;
}
.react-datepicker__navigation--previous {
    top: 16px !important;
    left: 16px !important;
    border-left-color: #ccc;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff !important;
}
.react-datepicker {
    font-family: "baloo_paaji_2medium" !important;
    font-size: 14px !important;
    background: black !important;
    color: #fff !important;
    border: 1px solid #161618 !important;
    margin: 20px 0 12px 0 !important;
    left: -84px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #fff !important;
}
.react-datepicker__day {
    border: 1px solid #161618 !important;
}
/* .react-datepicker__day:hover {
    border: 1px solid #fff !important;
    color: #fff !important;
    background-color: #161619 !important;
} */

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
    background: #161619 !important;
    color: #fff;
    border-radius: 6px;
    padding: 5px 0;
    width: 100%;
}
.react-date-picker__wrapper {
    width: 100%;
    border: none !important;
    /* background: red !important; */
}
.react-date-picker__inputGroup {
    /* padding: 5px 6px !important; */
}
.react-calendar {
    background: #161619 !important;
}
.react-date-picker__calendar .react-calendar {
    border: 1px solid #26272a !important;
}
/* .active.react-date-picker__calendar .react-calendar {
    border: 2px solid #272727 !important;
    border-radius:5px !important;
} */
/* .active.react-calendar__tile {
    color:red !important;
} */
/* .react-calendar__navigation button:enabled:hover, */
.react-calendar__navigation button:enabled:focus {
    background: #1b1c20 !important;
}
.react-calendar__tile--active {
    background: #161619 !important;
}
.react-calendar__tile:disabled {
    background: unset !important;
    color: #484848 !important;
}
.react-calendar__navigation button:disabled {
    background: unset !important;
}
.react-calendar__tile--now {
    background: transparent !important;
}
.react-date-picker_inputGroup_input--hasLeadingZero {
}
.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: 0 !important;
    padding-left: 0 !important;
}
.react-date-picker__calendar {
    width: 412px !important;
}
.react-calendar {
    width: 412px !important;
}
.tooltip---tooltip-inner---AMsNF {
    max-width: 320px;
    padding: 8px 16px;
    color: #fff;

    height: 60px;

    text-align: center;

    /* background: #4d4a4a; */

    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 1px solid #4d4a4a; */
    border-radius: 3px;
    /* -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); */
    /* -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); */
    /* box-shadow: 1px 1px 3px rgba(0,0,0,0.3); */
}

.react-date-picker__inputGroup__input--hasLeadingZero {
    padding-left: 0px !important;
    margin-left: 0px !important;
}
@keyframes classic {
    to {
        background-position: 80% 100%;
    }
}
.active.react-calendar {
    background: #1b1c1f !important;
    border: 2px solid #272727;
    border-radius: 5px;
    font-family: "gordita_regular";
    width: 490px;
    max-width: 100%;
    max-height: 340px;
    padding: 20px 15px;
}
@media all and (max-width: 650px) {
    .active.react-calendar {
        width: 100% !important;
    }
}

.active.react-calendar__month-view__weekdays__weekday {
    font-size: 14px !important;
    font-family: "gordita_medium" !important;
}
.react-calendar__tile {
    color: #fff !important;
}
.react-calendar__month-view__weekdays__weekday {
    color: #fff;
    font-family: "gordita_regular";
    text-transform: capitalize;
}
.react-calendar__month-view__weekdays__weekday:last-child {
    color: #f97b55;
}
.react-calendar__month-view__weekdays__weekday:first-child {
    color: #f97b55;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    color: #ffff;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from:hover {
    background: #1b1c1f !important;
}
.react-calendar__navigation__label:hover {
    background: #1b1c1f !important;
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:hover {
    background: #1b1c1f !important;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next-button:hover {
    background: #1b1c1f !important;
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
}
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
}
.react-calendar__navigation {
    align-items: center;
}
.react-calendar__tile--active {
    /* background:#F97B55 !important; */
    border-radius: 6px !important;
    background: #161619 !important;
}
.react-calendar__navigation__label {
}
.react-calendar__tile:enabled:hover {
    /* background: #f97b55 !important; */
    border-radius: 6px !important;
    background: #1b1c1f !important;
}
.react-calendar__navigation {
}
.CircularProgressbar .CircularProgressbar-path {
    stroke: #f7684b !important;
}
.CircularProgressbar .CircularProgressbar-text {
    fill: #fff !important;
    font-size: 22px !important;
}
/* ----------------------------------------------------------- */
.splide__slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.content-slider {
    background-color: white;
}
.imageslick {
    width: 90px !important;
    height: 30px !important;
}
.content-er > div .inner {
    min-height: 100px;
    padding: 2vw;
}

.slick-dots .slick-active {
    text-decoration: underline;
}

.toggle-content {
    display: none;
}

.slick-dots li {
    width: auto;
}
/* -------------------------------------------------------- */
.image-gallery-icon.image-gallery-left-nav {
    left: -9% !important;
    /* width: 30px; */
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 25px;
}
.image-gallery-icon.image-gallery-right-nav {
    right: -9% !important;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 25px;
}
.image-gallery-fullscreen-button {
    display: none;
}
.image-gallery-play-button {
    display: none;
}
html.modal-enabled {
    overflow: hidden !important;
}
.bVGmbA {
    color: #969595;
}
.react-date-picker__calendar {
    inset: none;
}
.react-date-picker__calendar--open {
    inset: 0px;
}

.react-time-picker__wrapper {
    border: none !important;
    text-align: center !important;
}
.react-time-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 6 + 4.217em * 2) !important;
    padding: 9px 6px !important;
}
.react-time-picker__inputGroup__input {
    font-size: 18px !important;
}
.react-date-picker__wrapper {
    text-align: center !important;
}

.codex-editor__redactor {
    padding: 90px 0px !important;
    min-height: 85vh !important;
}

.cdx-notifies {
    z-index: 1000 !important;
}
